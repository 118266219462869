@font-face {
  font-family: 'FontAwesome';
  src: url('FontAwesome.eot');
  src: url('FontAwesome.eot?#iefix') format('embedded-opentype'), url('FontAwesome.woff2') format('woff2'),
    url('FontAwesome.woff') format('woff'), url('FontAwesome.svg#FontAwesome') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Amazon Ember';
  font-weight: 400;
  src: url('AmazonEmber_W_Rg.eot');
  unicode-range: U+000-5FF;
  src: local('Amazon Ember'), url('AmazonEmber_W_Rg.eot?#iefix') format('embedded-opentype'),
    url('AmazonEmber_W_Rg.woff2') format('woff2'), url('AmazonEmber_W_Rg.woff') format('woff');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Amazon Ember';
  font-weight: 500;
  src: url('AmazonEmber_W_SBd.eot');
  unicode-range: U+000-5FF;
  src: local('Amazon Ember'), url('AmazonEmber_W_SBd.eot?#iefix') format('embedded-opentype'),
    url('AmazonEmber_W_SBd.woff2') format('woff2'), url('AmazonEmber_W_SBd.woff') format('woff');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Amazon Ember';
  font-weight: 600;
  src: url('AmazonEmber_W_Bd.eot');
  unicode-range: U+000-5FF;
  src: local('Amazon Ember'), url('AmazonEmber_W_Bd.eot?#iefix') format('embedded-opentype'),
    url('AmazonEmber_W_Bd.woff2') format('woff2'), url('AmazonEmber_W_Bd.woff') format('woff');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Amazon Ember';
  font-weight: 700;
  src: url('AmazonEmber_W_He.eot');
  unicode-range: U+000-5FF;
  src: local('Amazon Ember'), url('AmazonEmber_W_He.eot?#iefix') format('embedded-opentype'),
    url('AmazonEmber_W_He.woff2') format('woff2'), url('AmazonEmber_W_He.woff') format('woff');
  font-style: normal;
  font-display: swap;
}
