.pc-low--only,
.mobile--only {
  display: none;
}

/* >= Tablet or PC low resolution */
@media (max-width: 1123px) {
  .pc-low--off {
    display: none;
  }
  .pc-low--only {
    display: initial;
  }
  .nav__fill {
    -webkit-flex: 1 0 70%;
    flex: 1 0 70%;
    margin-right: 1rem;
  }
  .cat-scope span {
    max-width: 120px;
  }
}
/* >= Tablet */
@media (max-width: 949px) {
  .tablet--off {
    display: none;
  }
  .cat-scope span {
    max-width: 90px;
  }
}
/* >= Mobile */
@media (max-width: 779px) {
  .mobile--off {
    display: none;
  }
  .mobile--only {
    display: initial;
  }
  .container {
    width: 100%;
  }

  .search__filter {
    padding: 0 1rem;
  }
  .search__results,
  .card {
    margin: 1rem;
  }
  .cat-scope span {
    max-width: 50px;
  }

  .nav-main {
    padding-left: 5px;
  }
  .nav__fill .nav-main__item {
    max-width: 12rem;
  }
  .nav-main__item > a {
    padding: 6px 4px 1rem;
    font-size: 12px;
    line-height: 1.2;
  }
  .search__suggest {
    width: 40vw;
    left: unset;
  }
}
/* >= cell-phone landscape */
@media (max-width: 567px) {
  .nav-belt {
    padding: 0;
  }
  .nav-belt > * {
    padding: 6px 3px;
  }
  .nav-belt .dropdown {
    order: 1;
  }
  .phone--off {
    display: none;
  }
  .card {
    flex: 1 1 100%;
  }
  .sidebar {
    width: 32rem;
  }
  .cat-scope span {
    max-width: 25px;
  }
  .search__suggest {
    position: fixed;
    width: 82vw;
    left: 0;
  }
}
/* Mobile-xs portrait */
@media (max-width: 400px) {
  #root {
    width: 100%;
  }
  .sidebar {
    width: 28rem;
  }
  .sprite__loading--xl {
    height: 13px;
    width: 16px;
    background: url('src/assets/img/load-xs.gif');
  }
}
