#nav-bar {
  position: relative;
  top: 0;
  left: 0;
  font-size: 12px;
  line-height: 1em;
  zoom: 1;
}
#nav-bar * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/* NAV BELT */
.nav-belt {
  height: 6rem;
  padding: 0 2rem 0 1rem;
  background: var(--bk-common);
  flex-wrap: nowrap;
}

.nav-belt > .nav__brand,
.nav-belt > .nav__locator,
.nav-belt > .nav__search,
.nav-belt > .dropdown,
.nav-belt > .nav__cart {
  margin: 1px 0;
  padding: 6px 5px;
  border-radius: 2px;
  border: thin solid var(--black-0);
  color: var(--white-f);
  outline: none;
  cursor: pointer;
}
.nav-belt > :focus,
.nav-belt > :hover {
  border-color: var(--white-f);
}

.nav-belt .nav__line-1 {
  font-size: 1.2rem;
  line-height: 1.4rem;
  height: 1.4rem;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
}
.nav-belt .nav__line-2 {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.5rem;
}

/* NAV__BRAND, HOME PAGE */
.nav__brand {
  order: -1;
}
.logo__wrapper {
  font-size: 26px;
  font-weight: 600;
  line-height: 34px;
  color: var(--white-f);
}
.logo__wrapper .logo {
  width: 32px;
  height: 32px;
  object-fit: cover;
  margin: 0 0 -0.5rem 0;
}

/* LOCATOR */
.nav__locator {
  padding-top: 1rem;
  order: -1;
}
.nav__locator > div + div {
  margin-left: 4px;
}
.sprite__locator {
  width: 16px;
  height: 34px;
  background: url('src/assets/img/nav-sprite.png') -60px -120px;
}
.nav__locator .nav__line-1 {
  color: var(--rgb-ccc);
}

/* Nav SEARCH BOX*/
.nav__search {
  flex: 1 1 0;
  position: relative;
}
.nav__search:hover {
  border-color: transparent;
}
.search-box {
  display: flex;
  position: relative;
  border-radius: 4px;
  border-color: none;
  z-index: 9008;
  background: var(--white-f);
}
.search-box * {
  outline: none; /*safari*/
}
.search-box.focus,
.cat-scope.focus {
  box-shadow: 0 0 3px 3px var(--primary-3), 0 0 1px 3px var(--primary-3);
  z-index: 9000;
}
.box__left {
  display: flex;
  position: static;
  width: auto;
}
.box__middle--fill {
  flex: 1 0 0;
  position: relative;
}
.box__right {
  flex: 0 0 45px;
  display: flex;
}
.search-box__cat-scope {
  display: flex;
  user-select: none;
}
.search-box__cat-scope:focus {
  border: none;
  box-shadow: 0 0 2px 3px var(--primary-3), 0 0 2px 3px var(--active-light);
}
.cat-scope {
  display: flex;
  position: relative;
  float: left;
  height: 100%;
  margin: 0;
  padding-left: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-weight: 500;
  -webkit-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  border: 1px solid var(--white-f3);
  border-right: thin solid var(--rgb-ccc);
  color: var(--rgb-555);
  background: var(--white-f3);
  background: -webkit-linear-gradient(to bottom, var(--white-f3), var(--white-e2));
  background: linear-gradient(to bottom, var(--white-f3), var(--white-e2));
  overflow: hidden;
  cursor: pointer;
}
.cat-scope.focus {
  color: var(--black-f);
  background: var(--white-cb);
  border-color: var(--gray-6e-7);
  background: -webkit-linear-gradient(to bottom, var(--white-cb), var(--white-e2));
  background: linear-gradient(to bottom, var(--white-cb), var(--white-e2));
}
.cat-scope--facade {
  display: flex;
  position: relative;
  float: left;
  top: 3px;
}
.cat-scope span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 33px;
  margin-right: 21px;
  margin-left: 5px;
  min-width: 28px;
  width: min(200px, 100%);
}
.cat-scope i {
  position: absolute;
  width: 0;
  height: 0;
  top: 14px;
  right: 8px;
  border: 4px solid transparent;
  border-top-color: var(--rgb-666);
  border-bottom-width: 0;
  font-size: 0;
  line-height: 0;
}
.cat-scope.focus i {
  border-top-color: var(--black-f);
}
.cat-scope__dropdown {
  position: absolute;
  margin-top: 2px;
  padding: 4px 0;
  top: 36px;
  border-radius: 4px;
  background: var(--white-e2);
  background: -webkit-linear-gradient(to bottom, var(--white-cb), var(--white-f3), var(--white-e2));
  background: linear-gradient(to bottom, var(--white-cb), var(--white-f3), var(--white-e2));
  border: thin solid var(--gray-6e-7);
  box-shadow: 0 2px 10px 0px var(--gray-6e-7);
  user-select: none;
  z-index: 9000;
}
.cat-scope__dropdown .dropdown__list {
  width: 28rem;
  max-height: 36rem;
  font-size: 15px;
  line-height: 1.2;
  color: var(--black-f);
  overflow-y: scroll;
}
.category {
  width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
  padding: 0 6px 3px 6px;
  cursor: pointer;
}
.category:hover {
  color: var(--white-f);
  background: var(--blue-1);
  background: -webkit-linear-gradient(to right, var(--blue-2), var(--blue-1));
  background: linear-gradient(to right, var(--blue-2), var(--blue-1));
}
.category i {
  font-size: small;
  color: transparent;
}
.category.active i {
  color: var(--rgb-111);
}
.search__input,
.search__input input:focus,
.search__input input {
  display: flex;
  flex: 1;
  border-radius: 6px; /* safari problem, input default style override */
  box-shadow: none;
  border: none;
  box-sizing: border-box;
  outline: none;
}
.search__btn {
  display: flex;
  background: var(--primary-3n4);
  cursor: pointer;
}
.search__btn,
.search__btn input {
  flex: 1;
  width: 45px;
  border: none;
  border-radius: 0 4px 4px 0;
}
.search__btn:focus,
.search__btn:hover {
  background: var(--active-light);
  background: -webkit-linear-gradient(top, var(--primary-1n2), var(--active-light));
  background: linear-gradient(to bottom, var(--primary-1n2), var(--active-light));
}
.search__btn input {
  outline: 0;
  color: var(--white-f);
  background: none;
  border: none;
  text-indent: -1000px;
  cursor: pointer;
}
.search-box input:focus {
  border: none;
  margin: 0;
  box-shadow: none;
}
.sprite__search-btn {
  width: 21px;
  height: 24px;
  margin: auto;
  background: url('src/assets/img/nav-sprite.png') 0 -40px no-repeat;
  object-fit: cover;
}
.search__suggest {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9007;
}
.search__suggest ul:not(:empty) {
  border: thin solid var(--rgb-ccc);
  margin-right: -45px;
  outline: none;
}
.search__suggest li {
  margin: 0;
  background: var(--white-f);
}
.search__suggest li a {
  display: block;
  height: 100%;
  padding: 1rem;
  font-weight: 600;
  font-size: 1.5rem;
  color: var(--black-f);
}
.search__suggest li.active > a,
.search__suggest li > a:hover {
  background-color: var(--white-ed);
}
.search__suggest li b {
  color: var(--bk-nav);
  font-weight: 500;
  opacity: 0.85;
}

/* DROPDOWN CURRENCY */
.sprite.flag {
  height: 14px;
  width: 22px;
  margin: -1rem 3px 0 3px;
  box-shadow: 0 0 0 1px var(--rgb-888);
  background-image: url('src/assets/img/flags-globe.png');
}
.EUR {
  background-position: -29px -260px;
}
.GBP {
  background-position: -83px -386px;
}
.USD {
  background-position: -83px -404px;
}
.CZK {
  background-position: -3px -350px;
}
.PLN {
  background-position: -29px -314px;
}
.CHF {
  background-position: -83px -296px;
}
.sprite.flag.xl {
  height: 20px;
  width: 32px;
  margin: 0 1rem;
  background-image: url('src/assets/img/flags-globe.png');
  box-shadow: 0 0 5px var(--black-8);
}
.xl.EUR {
  background-position: -38px -26px;
}
.xl.GBP {
  background-position: -110px -194px;
}
.xl.USD {
  background-position: -110px -218px;
}
.xl.CZK {
  background-position: -2px -146px;
}
.xl.PLN {
  background-position: -38px -98px;
}
.xl.CHF {
  background-position: -110px -74px;
}

/* DROPDOWN MENU */
.dropdown {
  position: relative;
  cursor: pointer;
  user-select: none;
}
.dropdown .fa-caret-down {
  float: right;
  padding-left: 4px;
  font-size: 12px;
  color: var(--rgb-ccc);
}
.shadow-of__ {
  position: relative;
  grid-area: main;
  background-color: var(--black-8);
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
}
.shadow-of__.navSearch,
.shadow-of__.navDrop {
  opacity: 1;
  z-index: 9;
}

.dropdown__menu {
  position: absolute;
  width: 35rem;
  right: -29px;
  margin-top: 7px;
  padding: 10px 15px 10px 25px;
  border-radius: 3px;
  color: var(--bk-common);
  background-color: var(--white-f);
  box-shadow: 0 0 2px var(--bk-common);
  z-index: -9;
  opacity: 0;
  transition: 0s all 0.6s, opacity 0.2s ease-out 0.1s, z-index 0.3s;
}
.nav__user .dropdown__menu {
  right: -66px;
}
.nav__seller .dropdown__menu {
  right: -68px;
  width: 36.5rem;
}
.dropdown__menu::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  right: 120px;
  border: 0 solid transparent;
  border-right-width: 1rem;
  border-left-width: 1rem;
  border-bottom: 1rem solid var(--white-f);
}
.nav__user .dropdown__menu:after {
  right: 70px;
}
/* .dropdown:focus > .dropdown__menu.show, */
.dropdown:hover > .dropdown__menu.show {
  z-index: 9006;
  opacity: 1;
  right: -9px;
  width: 25rem;
  transition: all 0.4s ease-in-out, opacity 0.2s ease-out;
}
/* .dropdown:focus > .dropdown__menu.show::after, */
.dropdown:hover > .dropdown__menu.show::after {
  top: -1rem;
  right: 7px;
  transition: all 0.4s ease-in-out;
}

.dropdown__menu {
  cursor: default;
}
.dropdown > .dropdown__menu.show > * {
  opacity: 0;
}
/* .dropdown:focus > .dropdown__menu.show > *, */
.dropdown:hover > .dropdown__menu.show > * {
  opacity: 1;
  transition: opacity 0.4s ease-in;
}
.dropdown__menu li {
  margin: 0;
}
.dropdown__menu li > strong {
  font-size: 15px;
  line-height: 2.5;
}
.dropdown__menu li > div,
.dropdown__menu a {
  display: block;
  padding: 0.5rem 0;
  font-size: 13px;
  font-weight: 400;
  color: var(--rgb-111);
  cursor: pointer;
}
.dropdown__menu .menu__link-item:not(.disabled):hover,
.dropdown__menu a:hover {
  color: var(--active-light);
  text-decoration: underline;
}
.separator {
  width: 100%;
  height: 1px;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  margin-left: 0;
  margin-right: 0;
  background: var(--rgb-ccc);
}

/* NAV-ITEM CURRENCY */
.nav__line-2.sprite__wrapper {
  margin: 6px 0px 1px;
}
.nav__line-2 .sprite.flag + i {
  margin-top: -4px;
}

/* .nav__currency .dropdown__menu {
  left: -59px;
} */
.nav__currency .dropdown__menu.show {
  left: -69px;
  padding-left: 95px;
}
/* .nav__currency.dropdown:focus > .dropdown__menu.show, */
.nav__currency.dropdown:hover > .dropdown__menu.show {
  left: -13px;
  padding-left: 15px;
}
.nav__currency .dropdown__menu:after {
  left: 180px;
}
/* .nav__currency.dropdown:focus > .dropdown__menu.show:after, */
.nav__currency.dropdown:hover > .dropdown__menu.show:after {
  left: 44px;
  transition: all 0.4s ease-in-out;
}
.nav__currency .dropdown__menu .separator.ml-1 {
  margin-left: 25px;
  width: auto;
}
.nav__currency .dropdown__menu > * {
  font-size: 13px;
}

.nav__currency .dropdown__menu > li {
  line-height: 2;
}
.nav__currency .dropdown__menu .active * {
  cursor: default;
  color: var(--black-f);
  font-weight: normal;
}
.nav__currency .calculator {
  display: flex;
  justify-content: space-between;
}
.sprite__wrapper {
  display: flex;
  align-items: center;
}
.sprite.circle {
  height: 20px;
  width: 20px;
  margin: 0 3px;
  background-position: -74px 0px;
  background-image: url('src/assets/img/flags.png');
}
.nav__currency a:not(.disabled, .active):hover span {
  text-decoration: underline;
}
.nav__currency .menu__link-item:not(.disabled, .active):hover .circle,
.languages .language:not(.disabled, .active):hover .circle {
  background-position: -122px 0px;
}
.active .sprite.circle {
  background-position: -98px 0px;
}

/* CART */
.nav__cart .cart__counter {
  margin: 0 0 7px 6px;
  font-weight: 600;
  font-size: 18px;
  color: var(--active-light);
  text-align: center;
}
.nav__cart .cart__counter.--js-3digits {
  margin: -3px 0 10px 6px;
  font-weight: 700;
  font-size: 14px;
}
.nav__cart {
  padding: 7px;
}
.sprite__cart {
  width: 40px;
  height: 30px;
  background: url('src/assets/img/nav-sprite.png') 0 -88px;
  margin-top: -20px;
  margin-right: -2px;
}

/* NAV MAIN */
.nav-main,
.nav__left,
.nav__fill,
.nav__right {
  height: 28px;
  display: -webkit-flex;
  display: flex;
  float: none;
  color: var(--white-f);
  background: var(--bk-nav);
  flex-wrap: nowrap;
  overflow: hidden;
  white-space: nowrap;
}
.nav-main {
  height: 39px;
  margin-top: 0;
  padding: 0 11px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
}
.nav__left {
  flex: 0 0 6rem;
}
.nav-main__item {
  height: 100%;
  padding: 0;
  border-radius: 2px;
  border: thin solid var(--black-0);
  color: var(--white-f);
}
.nav-main__item > * {
  display: block;
  width: 100%;

  font-size: 15px;
  letter-spacing: -0.5px;
  color: var(--white-f);

  line-height: 1.2;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
.nav__left .nav-main__item {
  width: 100%;
  padding-top: 4px;
  cursor: pointer;
}
.nav__left .nav-main__item > div {
  padding: 0 1rem;
}
.nav__left .nav-main__item b {
  font-weight: 700;
}
.nav__left .nav-main__item:hover,
.nav__fill .nav-main__item:hover {
  border-color: var(--white-f);
}
.nav__fill {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  flex-wrap: wrap;
  min-width: var(--min-screen);
  margin-right: 2rem;
}
.nav__fill .nav-main__item {
  max-width: 18rem;
}
.nav__fill .nav-main__item > * {
  min-width: 6rem;
  padding: 4px 1rem 6px;
}
.nav__right {
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  height: 100%;
  margin: 0px;
  padding: 1px;
  border-radius: 0;
}
.nav__right .nav-main__item {
  display: block;
  width: 100%;
}
.nav-main .nav__right .nav-main__item:hover {
  outline: 1px solid var(--white-f);
  outline-offset: -1px;
}
.nav__right .nav-main__item > a {
  display: block;
  height: 100%;
  font-size: 22px;
  font-weight: 400;
  line-height: 10px;
  color: var(--white-ed);
}
.nav__right sup {
  font-size: 20px;
  font-weight: 600;
  color: var(--white-f);
}

.sprite__bars {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('src/assets/img/nav-sprite.png') -160px -180px;
  margin: -2px 5px 0 5px;
}

/* SIDEBAR MENU */
button.open-sidebar {
  font-size: 2.4rem;
  padding: 0.2rem 0.5rem;
  margin: 0 0.2rem;
  background: none;
  color: var(--white-f);
  cursor: pointer;
}
button.open-sidebar:hover {
  border-color: var(--white-f);
}
.scroll--off {
  width: 100vw;
  overflow: hidden;
  position: fixed;
}
.click-catcher {
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: var(--black-8);
  z-index: 9008;
}
.scope.click-catcher {
  background-color: var(--black-0);
  z-index: 0;
}
.sidebar .click-catcher {
  background-color: var(--black-b);
}
.sidebar {
  position: fixed;
  width: 35rem;
  height: 100%;
  background-color: var(--white-f);
  z-index: 9009;
  transform: translate3d(-40rem, 0, 0);
  transition: transform 0.2s ease-out;
}
.sidebar.opened {
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s cubic-bezier(0.56, 1.48, 0.87, 0.9);
}
#btn--close-sidebar {
  position: absolute;
  padding: 0.3rem 0.8rem;
  font-size: 3rem;
  top: 0.5rem;
  right: -4rem;
  background: none;
  color: var(--white-f);
  border: none;
}
#btn--close-sidebar:focus {
  outline: none;
  box-shadow: none;
}
.sprite__close-btn {
  width: 20px;
  height: 20px;
  background: url('src/assets/img/nav-sprite.png') -180px -40px;
  margin-top: 13px;
}
.sidebar__list {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  list-style: none;
  overflow-y: auto;
}
.sidebar li {
  display: flex;
  justify-content: space-between;
  margin: 0;
  line-height: 1;
  font-size: 1.8rem;
}
.sidebar li > * {
  display: block;
  width: 100%;
  padding: 1.3rem 2rem 1.3rem 3.6rem;
}
.sidebar li > strong {
  margin-top: 1rem;
}
.sidebar .menu__link-item {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--rgb-111);
}
.sidebar .menu__link-item:not(.disabled, .sidebar__header):hover {
  color: var(--rgb-111);
  background-color: var(--white-ed);
  cursor: pointer;
}
.sidebar .sidebar__header {
  display: inline-block;
  max-width: 90%;
  padding-bottom: 17px;
  font-weight: 700;
  font-size: 1.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar .sidebar__header,
.sidebar .sidebar__header:hover {
  color: var(--white-f);
  background: var(--bk-nav);
  cursor: pointer;
}
.sprite__user {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url('src/assets/img/nav-sprite.png') -125px -88px;
  margin: -3px 9px -8px -2px;
}
.sidebar .sprite.flag {
  padding: 0;
  margin: 16px 10px -30px 36px;
  pointer-events: none;
  z-index: 1;
}
.sidebar .pl-8 {
  padding-left: 8rem;
}

/* SUB NAV - CAT NAV */
.screen__header {
  height: 31px;
  border-top: thin solid var(--black-2);
  border-bottom: thin solid var(--black-1);
  color: var(--rgb-111);
  background: var(--white-fa);
  text-overflow: ellipsis;
  overflow: hidden;
}
.cat-nav {
  display: flex;
  flex-wrap: wrap;
  margin: -1px 2rem;
  font-size: 12px;
  font-weight: 400;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.cat-nav a {
  padding: 0;
  color: var(--rgb-111);
  margin: 15px 0;
  font-weight: 500;
}
.cat-nav li {
  flex: 0 1 auto;
  padding: 0 10px;
  max-width: 12rem;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}
.cat-nav li.active,
.cat-nav li.active a {
  color: var(--black-f);
  font-weight: 700;
}
.cat-nav a:hover,
.cat-nav li:hover {
  padding-bottom: 5px;
  color: var(--inner-focus-color);
  border-bottom: 2px solid var(--inner-focus-color);
}
/* .cat-nav li:not(:first-child).active {
  color: var(--white-f);
  text-decoration: underline;
} */

/* SEARCH BANNER, COUNT, SORT FILTER  */
.search__banner {
  border-top: 1px solid var(--rgb-ccc);
  border-bottom: 1px solid var(--rgb-ccc);
  box-shadow: 0 10px 10px var(--rgb-ddd);
  background-color: var(--white-f);
  position: relative;
}

.search__banner {
  box-sizing: border-box;
  padding: 8px 20px 9px;
}
.search__counter {
  margin-left: 2rem;
}
.sort__filter {
  position: relative;
  width: 18rem;
  margin-right: 2rem;
  border: thin solid var(--rgb-ddd);
  outline: 0;
  border-radius: 8px;
  background: var(--white-f3);
  box-shadow: 0 2px 5px var(--black-2);
}
.sort__filter label {
  position: absolute;
  top: 0;
  left: 8px;
  padding: 2px 0;
  font-size: 11.5px;
}
.sort__filter select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 3px 2rem 3px 5.2rem;
  font-size: 11.5px;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 7px;
}
.sort__filter .sprite__caret {
  top: 0px;
  right: 5px;
}
.sort__filter:hover,
.sort__filter:hover select {
  background: var(--white-e3);
}
.sort__filter optgroup {
  color: var(--gray-6e);
  font-style: normal;
  padding: 0;
}
.sort__filter option {
  color: var(--rgb-111);
}
