html {
  --white-f: #fff;
  --white-b: #fffb;
  --white-8: #fff8;
  --white-0: #fff0;

  --white-fa: #fafafa;
  --white-f3: #f3f3f3;
  --white-ed: #eaeded;
  --white-e2: #e2e2e2;
  --white-cb: #c7cbcf;

  --black-f: #000;
  --black-b: #000b;
  --black-8: #0008;
  --black-2: #0002;
  --black-1: #0001;
  --black-0: #0000;

  --rgb-eee: #eee;
  --rgb-ddd: #ddd;
  --rgb-ccc: #ccc;
  --rgb-bbb: #bbb;
  --rgb-aaa: #aaa;
  --rgb-888: #888;

  --gray-6e: #6e6e6e;
  --gray-6e-7: #6e6e6e70;
  --rgb-666: #666;
  --rgb-555: #555;
  --rgb-444: #444;

  --rgb-222: #222;
  --rgb-222-0: #2220;
  --rgb-222-a: #222a;
  --rgb-111: #111;

  --blue-1: #2b6bf1;
  --blue-2: #2666ec;
  --blue-3: #0066c0;
  --blue-4: #44a9e2;
  --blue-4-0: #44a9e200;
  --blue-5: #007185;

  --primary-1: #f7dfa5;
  --primary-1n2: #f4d078;
  --primary-2: #f0c14b;
  --primary-3: #f3a847;
  --primary-3n4: #febd69;
  --primary-4: #f0c040;

  --secondary-1: #f7f8fa;
  --secondary-2: #e7e9ec;
  --secondary-3: #d9dce1;

  --success-color: #20a020;
  --danger-color: #a02020;
  --deal-color: #b12704;

  --active-dark: #c35528;
  --active-light: #f80;
  --inner-focus-color: #e77600;
  --outer-focus-color: #e3781080;

  --bk-common: #131a21;
  --bk-nav: #232f3e;
  --bk-video-screen: #0e171e;
  --bk-deal-screen: #fbf4e4;
  --bk-deal-screen-0: #fbf4e400;

  --border-gold: thin solid #9c7e31;
  --border-gold-colors: #a88734 #9c7e31 #846a29;
  --border-silver: thin solid var(--rgb-888);
  --border-silver-colors: var(--rgb-bbb) var(--rgb-aaa) var(--rgb-888);

  font-size: 62.5%; /* 16px x 62.5 = 10px = 1rem */
  box-sizing: border-box;
  font-family: 'Amazon Ember', Arial, sans-serif;
  font-weight: 400;
  background-color: var(--white-ed);
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --min-screen: 360px;
}
body {
  height: 100vh;
  margin: 0;
  padding: 0;
  min-width: var(--min-screen);
  font-size: 1.4rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
*,
*:focus {
  margin: 0;
}
#root {
  height: 100%;
  overflow-x: hidden;
  width: calc(100vw);
  min-width: var(--min-screen);
}
/* *:not(input, textarea) {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */
.active {
  font-weight: 600;
}
[disabled],
[disabled] *,
[disabled]:hover,
.disabled,
.disabled *,
.disabled:hover {
  cursor: unset !important;
  color: var(--gray-6e) !important;
  outline: none !important;
  border-color: transparent !important;
}
.dark.disabled,
.dark.disabled *,
.dark.disabled:hover {
  color: var(--rgb-888) !important;
}

/* Error Page */
.error-page {
  min-height: 5rem;
  max-height: 40rem;
}

/* Layout */
.container--grid {
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-columns: 1fr;
  grid-template-rows: 99px 1fr 4rem;
  height: 100%;
}
header#nav-bar {
  grid-area: header;
  width: 100vw;
  min-width: var(--min-screen);
  background-color: var(--bk-common);
}
main {
  grid-area: main;
  position: relative;
  width: 100vw;
  min-width: var(--min-screen);
  z-index: 0;
}
footer {
  grid-area: footer;
  width: 100vw;
  min-width: var(--min-screen);
  background-color: var(--bk-common);
  color: var(--white-f);
}
.container {
  /* display: flex; */
  /* width: 100%; */
  /* max-width: 1500px; */
  padding: 0;
  justify-content: center;
  background: var(--white-8);
}
main.container {
  width: 100vw;
  min-width: var(--min-screen);
}
main > col-fill {
  margin-left: 1rem;
  margin-right: 1rem;
}
.container--full {
  width: auto;
  height: 100%;
  overflow-x: hidden;
}
.container--fluid {
  width: auto;
  height: 100%;
  margin-left: -1rem;
  margin-right: -1rem;
  overflow-x: hidden;
}
.col-fill {
  position: relative;
  float: none;
  width: 100%;
  height: 100%;
  -webkit-flex: 1 1 0px;
  flex: 1 1 0px;
}
.screen--light {
  background: -webkit-linear-gradient(to bottom, var(--white-f) 10%, var(--white-ed) 80%);
  background: linear-gradient(to bottom, var(--white-f) 10%, var(--white-ed) 80%);
}
.row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.row.center {
  justify-content: center;
}
.row.top {
  align-items: flex-start;
}
.row.start {
  justify-content: flex-start;
}
.row.end {
  justify-content: flex-end;
}
.col-1 {
  flex: 1 1 20rem;
}
.col-2 {
  flex: 2 1 50rem;
}
.col-3 {
  flex: 17 1 30rem;
}
.col-50p {
  flex: 0 1 49%;
  width: 49%;
}
.min-20 {
  min-width: 20rem;
}
.max-30 {
  max-width: 30rem;
}
.p-1 {
  padding: 1rem;
}
.pr-1 {
  padding-right: 1rem;
}
.pr-3 {
  padding-right: 3rem;
}
.m-0 {
  margin: 0 !important;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.ml-1 {
  margin-left: 1rem;
}
.mh-2 {
  margin: 0 2rem;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
/* Common */
h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}
h2 {
  font-size: 1.6rem;
  padding: 1rem 0;
}
a {
  color: var(--rgb-111);
  text-decoration: none;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
li {
  margin-top: 1rem;
}
li a {
  font-weight: 400;
}
.strike {
  text-decoration: line-through;
}
input,
select,
textarea,
button {
  padding: 1rem;
  border-radius: 3px;
  border: var(--border-silver);
  border-color: var(--border-silver-colors);
  font-weight: 500;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--secondary-2);
  background: -webkit-linear-gradient(top, var(--white-f), var(--secondary-1));
  background: linear-gradient(to bottom, var(--white-f), var(--secondary-1));
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border-radius: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.select-wrapper {
  position: relative;
  outline: 0;
}
.select-wrapper select {
  width: 100%;
  padding-right: 2rem;
}
.sprite__caret {
  position: absolute;
  right: 6px;
  top: 4px;
  width: 18px;
  height: 18px;
  background: url('src/assets/img/nav-sprite.png') -170px -105px;
  opacity: 0.7;
  pointer-events: none;
}
.sprite__caret.xl {
  top: 9px;
}
input,
textarea {
  background: var(--white-f);
  resize: vertical;
  max-height: 40rem;
}
input[type='button'],
button {
  display: inline-block;
  text-align: center;
  text-decoration: none !important;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
  background: var(--white-f3);
  background: -webkit-linear-gradient(top, var(--secondary-1), var(--secondary-2));
  background: linear-gradient(to bottom, var(--secondary-1), var(--secondary-2));
}
select:not(.disabled, [disabled]):hover,
button:not(.disabled, [disabled]):hover {
  background: var(--white-e2);
  background: -webkit-linear-gradient(top, var(--secondary-2), var(--secondary-3));
  background: linear-gradient(to bottom, var(--secondary-2), var(--secondary-3));
  cursor: pointer;
}
input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: 0;
  border: 1px solid var(--inner-focus-color);
  box-shadow: 0 0 3px 3px var(--outer-focus-color);
}
.btn.primary,
button.primary {
  background: var(--primary-1n2);
  background: -webkit-linear-gradient(top, var(--primary-1), var(--primary-2));
  background: linear-gradient(to bottom, var(--primary-1), var(--primary-2));
  color: var(--rgb-111);
}
.btn.primary:not(.disabled, [disabled]):hover,
button.primary:not(.disabled, [disabled]):hover {
  background: var(--primary-2);
  background: -webkit-linear-gradient(top, var(--primary-1), var(--primary-3));
  background: linear-gradient(to bottom, var(--primary-1), var(--primary-3));
  border: var(--border-gold);
  border-color: var(--border-gold-colors);
}
button.block {
  width: 100%;
}
button.small {
  font-size: 1.2rem;
}
.flex {
  display: -webkit-flex;
  display: flex;
}
.flex-col {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

/* Image */
img {
  border-radius: 3px;
}
img.small {
  max-width: 5rem;
  width: 100%;
  object-fit: contain;
}
img.thumbnail {
  max-width: 30rem;
  width: 100%;
  height: 20rem;
  object-fit: contain;
}
img.medium {
  max-width: 29rem;
  width: 100%;
  object-fit: contain;
}
img.large {
  width: 100%;
  object-fit: contain;
}

/* Search Screen */
.search-screen__result {
  background: var(--white-f);
  padding: 0 1rem;
  box-sizing: border-box;
}
.search__filter {
  flex: 1 0 18rem;
  margin: 3rem 0;
  border-right: 2px solid var(--rgb-ccc);
  padding: 0 3rem;
  max-width: 21rem;
}
.search__filter ul {
  margin-top: -2rem;
}
.search__filter .rating span:last-child {
  color: var(--rgb-111);
  cursor: pointer;
}
.search__filter .rating:hover span:last-child {
  color: var(--active-dark);
}
.col-9 {
  flex: 12 12 1%;
}
.search__results {
  margin-top: 2rem;
  justify-content: center;
}

/* Card */
.placeholder,
.card {
  flex: 1 1 26%;
  margin: 2rem;
  max-width: 27rem;
  padding: 1rem;
}
.card {
  padding: 0.5rem 2rem 0 2rem;
  border-radius: 3px;
  background: var(--white-f);
  border-bottom: 2px solid var(--rgb-ccc);
}
.card__center {
  margin: 0 auto;
  width: 100%;
}
.card__body {
  margin-bottom: 2rem;
}
.card__body > * {
  margin-bottom: 1rem;
}
.card__body h2 {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.price {
  font-size: 2rem;
  font-weight: 600;
}
.price.danger {
  font-weight: 600;
  color: var(--deal-color) !important;
}
.price sup {
  font-size: 1rem;
}
/* Rating */
.rating span {
  color: var(--primary-4);
  margin: 0.1rem;
}
.rating .fa {
  font-size: 2rem;
  color: var(--primary-3);
}
.rating span:last-child {
  color: var(--blue-3); /*review counts*/
  cursor: default;
}

/* Pagination */
.pagination > * {
  padding: 1rem 1.5rem;
  margin: 0.5rem;
  border-radius: 3px;
  border: var(--border-silver);
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--rgb-111);
  border-color: var(--border-silver-colors);
  background: var(--white-f3);
  background: -webkit-linear-gradient(top, var(--secondary-1), var(--secondary-2));
  background: linear-gradient(to bottom, var(--secondary-1), var(--secondary-2));
}
.pagination,
.pagination ~ div {
  margin-top: 2rem;
  z-index: 1;
  text-align: center;
}
.pagination > *:not(.disabled, [disabled]):hover {
  font-weight: 700;
  color: var(--black-f);
  background: var(--white-e2);
  cursor: pointer;
}
.pagination > *.active {
  font-weight: 700;
  color: var(--active-dark);

  border: thin solid var(--active-dark);
  background: var(--white-f);
}

/* Alert */
.loading {
  display: block !important;
  padding: 0 1rem;
}
.sprite__loading {
  display: inline-block;
  height: 13px;
  width: 16px;
  background: url('src/assets/img/load-xs.gif');
}
.sprite__loading--xl {
  display: inline-block;
  height: 52px;
  width: 64px;
  background: url('src/assets/img/load.gif');
}
.bold {
  font-weight: bold;
}
.success {
  color: var(--success-color);
}
.danger {
  color: var(--danger-color);
}
.alert {
  width: 100%;
  margin: 3px 0 !important;
  padding: 1rem;
  border: none;
  border-radius: 3px;
  box-sizing: border-box;
  list-style-type: none;
}
.alert.xs {
  margin: 1px 0 !important;
  padding: 0 1rem 2px;
}
.alert--info {
  color: var(--bk-common);
  background-color: #ceeaed;
  background: -webkit-linear-gradient(to right, #ceeaed 80%, #ceeaed00 90%);
  background: linear-gradient(to right, #ceeaed 80%, #ceeaed00 90%);
}
.alert--danger {
  color: var(--danger-color);
  background-color: #ffe0e0;
  background: -webkit-linear-gradient(to right, #ffe0e0 80%, #ffe0e000 90%);
  background: linear-gradient(to right, #ffe0e0 80%, #ffe0e000 90%);
}
.alert--success {
  color: var(--success-color);
  background-color: #eeffe0;
  background: -webkit-linear-gradient(to right, #eeffe0 80%, #eeffe000 90%);
  background: linear-gradient(to right, #eeffe0 80%, #eeffe000 90%);
}
/* Form */
.form {
  min-width: calc(var(--min-screen) - 2rem);
  width: calc(100% - 4rem);
  max-width: 68rem;
  margin: 0 auto;
}
.form > div:not(.flex) {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}
.form label {
  margin: 1rem 0;
}
.xs label {
  margin-top: 0;
}
.form [type='radio'],
.form [type='checkbox'] {
  margin-right: 1rem;
  order: -1;
  align-self: center;
}

/* Checkout Steps */
.checkout__steps > div {
  border-top: 3px solid var(--rgb-ccc);
  color: var(--rgb-ccc);
  flex: 1;
  padding: 1rem;
  font-weight: 700;
}
.checkout__steps > div.active {
  border-top-color: var(--active-light);
  color: var(--active-light);
}

/* Table */
.table {
  width: 100%;
  border-collapse: collapse;
}
.table tbody tr:nth-of-type(odd) {
  background-color: var(--white-ed);
}
table td,
table th {
  text-align: left;
  padding: 0.5rem;
}
.table tr td:first-child,
.table tr td:nth-child(2) {
  word-break: break-word;
}
.table button {
  margin: 0 0.2rem;
}
.tab__w12 {
  width: 12rem;
  min-width: 11.5rem;
}
.tab__w9 {
  width: 9rem;
  min-width: 8.5rem;
}
.tab__w6 {
  width: 6rem;
  min-width: 5.5rem;
}
.tab__w3 {
  width: 3rem;
  min-width: 2.5rem;
}
.tab__rest {
  flex: 1 1 30rem;
  min-width: 6rem;
}

/* Carousel */
.home-screen {
  position: relative;
  min-width: var(--min-screen);
  margin: 0;
  z-index: 1;
  --screen-gradient: to bottom, var(--white-0) 40%, var(--white-ed) 80%;
}
.home__banner.home {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 110vh;
  background: url('src/assets/img/banner-bestseller.jpg') center 0 no-repeat;
  z-index: -1;
}
.home__banner.bestseller {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('src/assets/img/banner-fs.jpg') center 0 no-repeat;
  z-index: -1;
}

.home-screen__title {
  position: relative;
  top: 9rem;
  left: 24rem;
  margin: 0 auto;
  font-size: 26px;
  font-weight: 600;
  text-align: left;
  color: var(--white-fa);
  z-index: 1;
}

.home-screen .swiper-slide {
  text-align: center;
  font-size: 18px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.home-screen .swiper-button-prev,
.home-screen .swiper-button-next {
  width: 8rem;
  height: 25rem;
  top: -3rem;
  border-radius: 0;
  color: var(--white-ed);
  background: transparent;
  text-shadow: 2px 2px 2px var(--rgb-111);
}
.home-screen .swiper-button-next {
  right: 0;
}
.home-screen .swiper-button-prev {
  left: 0;
}
.home-screen .swiper-container {
  position: relative;
  margin: 0 auto auto;
  padding-top: 20rem;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  list-style: none;
  background: -webkit-linear-gradient(var(--screen-gradient));
  background: linear-gradient(var(--screen-gradient));
  z-index: 1;
}
.home-screen .carousel-container {
  display: flex;
  position: relative;
  margin: 0 auto;
  padding-top: 10rem;
  padding-bottom: 2rem;
  margin-bottom: 4rem;
  background: -webkit-linear-gradient(var(--screen-gradient));
  background: linear-gradient(var(--screen-gradient));
  overflow-x: hidden;
  z-index: 0;
}
.home-screen .seller__card,
.home-screen .swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white-f);
  width: 28rem;
  height: 30rem;
  padding: 1rem;
  border-radius: 0;
}
.swiper-pagination {
  margin-bottom: -12px;
}
.screen__title {
  position: relative;
  font-size: 26px;
  text-align: center;
  color: var(--rgb-111);
  z-index: 1;
}
.screen__featured {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  transform: translate3d(0, 0, 0);
}
.home-screen .seller__img {
  width: 25rem;
  height: 25rem;
  object-fit: contain;
}
.home-screen .legend {
  font-size: 2rem;
  font-weight: 500;
}
.home-screen .card {
  border-radius: 0;
  border: none;
}
.home-screen .card__center {
  width: 100%;
}
.home-screen .card .price {
  font-weight: 500;
  font-size: 2rem;
  color: var(--deal-color) !important;
}
.home-screen .card__body a {
  font-weight: 500;
  color: var(--blue-5);
}
.home-screen .card__body a:hover {
  color: var(--active-dark);
}

/* Map */
.address-box {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  margin: 5px auto;
  width: 50%;
  min-width: calc(var(--min-screen) - 10rem);
  height: 40px;
  display: flex;
}
.address-box .btn-left {
  margin-right: 5px;
  border-radius: 3px;
}
.address-box input {
  border-radius: 3px 0 0 3px;
  border-right: 0;
  height: auto;
}
.address-box .btn-right {
  margin-left: -2px;
  border-radius: 0 3px 3px 0;
  border-left: 0;
}
.address-box *:focus {
  z-index: 1;
}

/* minor Effect */
.divider,
.divider-inner {
  height: 44px;
  /* margin-bottom: -18px; */
  background: -webkit-linear-gradient(to bottom, var(--black-2), var(--black-1) 3px, var(--black-0));
  background: linear-gradient(to bottom, var(--black-2), var(--black-1) 3px, var(--black-0));
  z-index: 0;
}
.divider-inner::after {
  content: '';
  display: block;
  width: 100%;
  height: 44px;
  background-color: var(--black-0);
  background: -webkit-linear-gradient(to right, var(--white-b), var(--white-0), var(--white-b));
  background: linear-gradient(to right, var(--white-b), var(--white-0), var(--white-b));
  z-index: 1;
}

/* Firefox will-change issue */
.react-multi-carousel-track {
  will-change: auto !important;
}

.no-image {
  background: var(--black-2) !important;
}

/* product edit screen */
.product-edit .img-row {
  align-items: start;
  gap: 0.5rem;
  margin: 0.5rem 0;
}
.img-row .img-row__input {
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
}

/* tool tip */
.tooltip-container {
  position: relative;
  z-index: 9;
  background: var(--white-f);
}

.tooltip-box {
  position: absolute;
  padding: 3px;
  border-radius: 3px;
  bottom: calc(100% + 3px);
  display: none;
  background: var(--primary-1);
  background: -webkit-linear-gradient(bottom, var(--primary-1), var(--secondary-1));
  background: linear-gradient(to top, var(--primary-1), var(--secondary-1));
  border: solid 1px;
  border-color: var(--border-silver-colors);
  color: var(--black-f);
  z-index: 99;
}

.tooltip-box.visible {
  display: block;
}

.tooltip-arrow {
  position: absolute;
  bottom: -14px;
  left: calc(50% - 6px);
  border-width: 6px;
  border-style: solid;
  border-color: var(--primary-2) transparent transparent transparent;
}
.NOT.AVAILABLE,
.NOT.IMPLEMENTED {
  cursor: not-allowed !important;
}
